<template>
	<v-card>
		<v-alert
			v-model="alert"
			border="right"
			:color="'#9EB8F6'"
			dark
			dense
			shaped
			style="border-radius: 10px 10px 10px 10px; z-index: 5; width: 100%; right: 0px; top: 2%; margin-top: ;}"
		>
			<div>
				<v-row justify="center">
					<v-col lg="12" cols="12">
						<b>Producto Terminado:</b>
						{{ viewItemSearch.UwoMaterialListName }}
					</v-col>
				</v-row>
			</div>
		</v-alert>

		<v-col
			cols="12"
			md="12"
			style="margin-left: 0px; padding: unset; margin-top: -14px"
		>
			<s-toolbar
				save
				@save="save($event)"
				:color="'#BAB6B5'"
				dark
				label=""
				close
				@close="closeModal()"
			/>
		</v-col>

		<v-row>
			<div class="col-md-4">
				<v-row>
					<div class="col-md-12">
						<v-col cols="12" md="12">
							<v-row justify="center">
								<v-col cols="7" md="7">
									<s-select
										clearable
										label="Nro. Pedido"
										:items="itemsOrder"
										item-text="OrdID"
										item-value="OrdID"
										v-model="items.OrdID"
										full
									>
									</s-select>
								</v-col>
								<v-col cols="5" md="5">
									<s-select-definition
										:def="1278"
										label="Turno"
										v-model="items.TypeTurn"
									/>
								</v-col>
								<v-col
									cols="12"
									md="12"
									style="margin-top: -15px"
								>
									<s-select-definition
										:def="1279"
										label="Calidad Pallete"
										v-model="items.TypeQualityPallet"
									/>
								</v-col>
								<v-col
									cols="12"
									md="12"
									style="margin-top: -15px"
								>
									<s-select-definition
										:def="1171"
										label="Procedencia"
										v-model="items.TypeOrigin"
									/>
								</v-col>
							</v-row>
						</v-col>
					</div>

					<div class="col-md-12" style="margin-top: -30px;">
						<v-col cols="12" md="12">
							<v-card outlined>
								<v-row justify="center" style="margin: auto">
									<v-col
										cols="12"
										md="12"
										style="margin-top: -15px"
									>
										<s-toolbar
											dark
											:color="'#BAB6B5'"
											style=" height: 28px; -webkit-box-shadow: 2px 1px 0px 0px rgb(0 0 0 / 10%), 0px 0px 0px 0px rgb(0 0 0 / 4%), 0px 1px 0px 0px rgb(0 0 0 / 2%); border-radius: 5px; col-md-6;"
											label="Pesado"
										>
										</s-toolbar>
									</v-col>
									<v-col
										cols="6"
										md="6"
										style="margin-top: -15px"
									>
										<s-text
											decimal
											label="Peso Neto estándar"
											readonly
											v-model="viewItemSearch.UwoPlannedQty"
										/>
									</v-col>

									<v-col
										cols="6"
										md="6"
										style="margin-top: -15px"
									>
										<s-text decimal label="Peso Real" v-model="PltGrossWeight"/>
									</v-col>
								</v-row>
							</v-card>
						</v-col>
					</div>
				</v-row>
			</div>

			<v-col cols="12" lg="8" md="8">
				<v-col cols="12">
					<v-card-title style="margin-top: -40px">
						Busqueda
						<v-spacer></v-spacer>
						<v-text-field
							v-model="search"
							append-icon="mdi-magnify"
							label="Search"
							single-line
							hide-details
						></v-text-field>
					</v-card-title>
					<v-data-table
						item-key="UwoOrderProductionID"
						dense
						fixed-header=""
						v-model="selectedSearch"
						:items="itemsSearch"
						:headers="headersSearch"
						:items-per-page="5"
						:search="search"
						@click:row="rowClickSearch"
					>
					</v-data-table>
				</v-col>
			</v-col>
		</v-row>

		<v-row justify="center" style="margin-top: auto">
			<div class="col-md-6" style="margin-top: -20px">
				<v-col
					cols="12"
					md="12"
					v-if="viewItemSearch.UwoOrderProductionID !== undefined"
				>
					<v-row justify="center">
						<v-col cols="12" md="12">
							<s-toolbar
								dense
								dark
								:color="'#BAB6B5'"
								style=" height: 28px; -webkit-box-shadow: 2px 1px 0px 0px rgb(0 0 0 / 10%), 0px 0px 0px 0px rgb(0 0 0 / 4%), 0px 1px 0px 0px rgb(0 0 0 / 2%); border-radius: 5px; col-md-6;"
								label="Saldos Pallet"
							>
							</s-toolbar>
						</v-col>
						<v-col cols="12" style="margin-top: -25px">
							<v-data-table
								item-key="PltID"
								dense
								:items-per-page="5"
								:items="itemsBalance"
								:headers="headersBalance"
								v-model="selectedBalance"
								
								@click:row="rowClickBalance"
							>
								<template v-slot:item.PltID="{ item }">
									<v-chip
										style="margin: 0px"
										x-small
										:color="'info'"
									>
										Pallet-{{ item.PltID }}
									</v-chip>
								</template>
							</v-data-table>
						</v-col>
					</v-row>
				</v-col>
			</div>
			<div class="col-md-6" style="margin-top: -20px">
				<v-col
					cols="12"
					md="12"
					v-if="viewItemSearch.UwoOrderProductionID !== undefined"
				>
					<v-row justify="center">
						<v-col cols="12" md="12">
							<s-toolbar
								dense
								dark
								add
								@add="newItem()"
								:color="'#BAB6B5'"
								style=" height: 28px; -webkit-box-shadow: 2px 1px 0px 0px rgb(0 0 0 / 10%), 0px 0px 0px 0px rgb(0 0 0 / 4%), 0px 1px 0px 0px rgb(0 0 0 / 2%); border-radius: 5px; col-md-6;"
								label="Detalle Pallet"
							>
							</s-toolbar>
						</v-col>
						<v-col cols="12" style="margin-top: -25px">
							<v-data-table
								item-key="PldID"
								dense
								:items-per-page="5"
								:items="itemsBalanceDetail"
								:headers="headersBalanceDetail"
								v-model="selectedBalanceDetail"
								
								@click:row="rowClickBalanceDetail"
							>
								<template v-slot:item.RcfID="{ item }">
									<v-chip
										style="margin: 0px"
										x-small
										:color="'info'"
									>
										LP-{{ item.RcfID }}
									</v-chip>
								</template>

								<template
									v-slot:item.PldQuantityBoxes="{ item }"
								>
									<s-text
										v-model="item.PldQuantityBoxes"
										number
									>
									</s-text>
								</template>

								<template v-slot:item.PldFruitxBoxe="{ item }">
									<s-text v-model="item.PldFruitxBoxe" number>
									</s-text>
								</template>
							</v-data-table>
						</v-col>
					</v-row>
				</v-col>
			</div>

			<div class="col-md-12" style="margin-top: -20px">
				<v-col
					cols="12"
					md="12"
					v-if="viewItemSearch.UwoOrderProductionID !== undefined"
				>
					<v-row justify="center">
						<v-col cols="12" md="12">
							<s-toolbar
								dense
								dark
								remove
								@removed="removeItem()"
								:color="'#BAB6B5'"
								style=" height: 28px; -webkit-box-shadow: 2px 1px 0px 0px rgb(0 0 0 / 10%), 0px 0px 0px 0px rgb(0 0 0 / 4%), 0px 1px 0px 0px rgb(0 0 0 / 2%); border-radius: 5px; col-md-6;"
								label="Detalle de Cajas"
							>
							</s-toolbar>
						</v-col>
						<v-col cols="12" style="margin-top: -25px">
							<v-data-table
								item-key="PldLine"
								dense
								:items-per-page="-1"
								:items="itemsDetail"
								:headers="headersDetail"
								v-model="selectedDetail"
								hide-default-footer
								@click:row="rowClickDetail"
							>
							</v-data-table>
							<b>Total Cajas: {{ sumTotalBoxes }}</b>
						</v-col>
					</v-row>
				</v-col>
			</div>
		</v-row>
	</v-card>
</template>

<script>
	//Service
	import _sPackingService from "../../../services/FreshProduction/PackingService";
	import SSelectOrder from "../../../components/FreshProduction/Packing/sSelectOrder.vue";

	export default {
		components: { SSelectOrder },
		props: {
			itemsRe: {
				required: true,
				default: null,
			},
			ordAreProduction: {
				required: true,
				default: "",
			},
			TypeDestiny: {
				required: true,
				default: 0
			}
		},
		data: () => ({
			items: {},
			//*************************************************************** */
			headersSearch: [
				{
					text: "Nro. Orden Produccion",
					value: "UwoOrderProductionID",
					width: 120,
				},
				{ text: "Cliente", value: "UwoCustomerName", width: 250 },
				{ text: "Cultivo", value: "TypeCultiveName", width: 100 },
				{ text: "Tipo Cultivo", value: "TypeCropName", width: 100 },
				{
					text: "Centro de Produccion",
					value: "UfdProductionCenter",
					width: 250,
				},
				{
					text: "Cantidad",
					value: "UwoMaterialListNumberBoxes",
					width: 50,
				},
			],

			viewItemSearch: {},
			itemsSearch: [], //Listado
			itemsOrder: [],
			selectedSearch: [],
			search: "",
			alert: true,

			//*************************************************************** */
			headersBalance: [
				{ text: "Pallet", value: "PltID", width: 50 },
				{
					text: "Cantidad",
					value: "UwoMaterialListNumberBoxes",
					width: 50,
				},
				{ text: "Cultivo", value: "TypeCultiveName", width: 120 },
				{ text: "Tipo Cultivo", value: "TypeCropName", width: 120 },
				{ text: "Variedad", value: "VrtName", width: 120 },
			],
			itemsBalance: [],
			selectedBalance: [],

			headersBalanceDetail: [
				{ text: "Nro. Lote Pro.", value: "RcfID", width: 100 },
				{ text: "Cantidad Disponible", value: "PldQuantityBoxesAvailable", width: 50 },
				{ text: "Cantidad", value: "PldQuantityBoxes", width: 50 },
				{ text: "Calibre", value: "TypeCaliberName", width: 100 },
				{ text: "Variedad", value: "VrtName", width: 120 },
				{ text: "Fruta x Caja", value: "PldFruitxBoxe", width: 100 },
			],
			itemsBalanceDetail: [],
			selectedBalanceDetail: [],

			//*************************************************************** */

			headersDetail: [
				{ text: "Variedad", value: "VrtName", width: 120 },
				{ text: "Calibre", value: "TypeCaliberName", width: 150 },
				{ text: "Fruta x Caja", value: "PldFruitxBoxe", width: 100 },
				{ text: "Nro. Lote", value: "RcfID", width: 100 },
				{ text: "Cantidad", value: "PldQuantityBoxes", width: 250 },
			],

			itemsDetail: [],
			itemsDetailRemoved: [],
			selectedDetail: [],
			sumTotalBoxes: 0, //Suma total de Cajas en detalle
			pldFruitxBoxe: 0,
			PltGrossWeight: 0,
		}),

		created() {
			this.itemsBalance = this.itemsRe;
			this.initialize();
			console.log('ordAreProduction ', this.itemsRe);
		},

		watch: {
			//Cargar la Ordenes de Pedido
			selectedSearch() {
				//Cargar Nro Pedido
				_sPackingService
					.GetOrder(
						{
							OrdAreProduction: this.ordAreProduction,
							UwoCustomerID: this.viewItemSearch.UwoCustomerID,
							UwoOrderProductionID:
								this.viewItemSearch.UwoOrderProductionID,
						},
						this.$fun.getUserID()
					)
					.then((r) => {
						this.itemsOrder = r.data;
					});

				this.alert = true;
			},

			itemsDetail() {
				this.sumTotalBoxes = this.isSumBoxes();
			},
		},

		methods: {
			//Emitir evento
			closeModal() {
				this.$emit("close");
			},

			//Cuando seleccionado tipo de destino
			click(val) {
				this.ordAreProduction = val.DedAbbreviation;
				this.filter.ordAreProduction = this.ordAreProduction;
				this.initialize();
				this.openDialog = false;
			},

			//Listado de Pedido - Clientes
			initialize() {
				_sPackingService
					.GetSearch(
						{ OrdAreProduction: this.ordAreProduction },
						this.$fun.getUserID()
					)
					.then((r) => {
						this.itemsSearch = r.data;
					});
			},

			rowClickSearch: function (item, row) {
				if (item !== undefined) {
					this.selectedSearch = [item];

					this.viewItemSearch = this.selectedSearch[0];
				}
			},

			rowClickBalance: function (item, row) {
				if (item !== undefined) {
					this.selectedBalance = [item];
					console.log("this.selectedBalance", this.selectedBalance);
					this.itemsDetailBalance(this.selectedBalance[0].PltID);
				}
			},

			rowClickBalanceDetail: function (item, row) {
				if (item !== undefined) {
					this.selectedBalanceDetail = [item];
					console.log(
						"this.selectedBalanceDetail",
						this.selectedBalanceDetail
					);
				}
			},

			rowClickDetail: function (item, row) {
				if (item !== undefined) {
					this.selectedDetail = [item];
				}
			},

			//*************************************************************** */
			//*************************************************************** */

			//Cargar Detalle de Pallet de Saldo
			itemsDetailBalance(PltID) {
				_sPackingService
					.GetDetail(
						{
							PltID: PltID,
						},
						this.$fun.getUserID()
					)
					.then((r) => {
						this.itemsBalanceDetail = r.data;
						this.itemsBalanceDetail.forEach(element => {
							element.PldQuantityBoxesAvailable = element.PldQuantityBoxes;
						}) 
						this.recalculateQuantityBox();
					});
			},

			//Diferencia de Jabas
			isDifferenceBoxes() {
				var difference = 0;
				difference =
					this.viewItemSearch.UwoMaterialListNumberBoxes -
					this.sumTotalBoxes;
				return difference;
			},

			//Suma de Jabas
			isSumBoxes() {
				var total = 0;
				if (this.itemsDetail.length > 0) {
					this.itemsDetail.forEach((element) => {
						total += parseInt(element.PldQuantityBoxes);
					});
				}

				return total;
			},

			//Obtener Fundo representativo
			getFundRepresentative() {
				var max = 0;
				var FagID = 0;
				this.itemsDetail.forEach((element) => {
					if (max <= element.PldQuantityBoxes) {
						max = element.PldQuantityBoxes;
						FagID = element.FagID;
					}
				});
				console.log("getFundRepresentative", FagID);
				return FagID;
			},

			//Obtener Lote de Produccion representativo
			getLotRepresentative() {
				var max = 0;
				var LotID = 0;
				this.itemsDetail.forEach((element) => {
					if (max <= element.PldQuantityBoxes) {
						max = element.PldQuantityBoxes;
						LotID = element.RcfID;
					}
				});

				console.log("getLotRepresentative", LotID);
				return LotID;
			},

			isValidatedDetail(item) {
				var isValidated = true;

				console.log("isValidatedDetail", item);
				if (
					this.viewItemSearch.UwoMaterialListNumberBoxes == undefined ||
					this.viewItemSearch.UwoMaterialListNumberBoxes == 0
				) {
					this.$fun.alert(
						"Debe Seleccionar una Orden de Produccion",
						"warning"
					);
					return;
				}

				if (item == undefined) {
					this.$fun.alert(
						"Seleccione un registro de Detalle Pallet",
						"warning"
					);
					isValidated = false;
					return;
				}

				if (item.PldQuantityBoxes <= 0) {
					this.$fun.alert("La cantidad debe ser mayor a 0", "warning");
					isValidated = false;
					return;
				}

				if (item.PldQuantityBoxes > this.isDifferenceBoxes()) {
					this.$fun.alert(
						"Esta superando la Cantidad de Cajas solicitadas segun Orden de Produccion",
						"warning"
					);
					isValidated = false;
					return;
				}

				if (item.PldFruitxBoxe <= 0) {
					this.$fun.alert("Ingrese Fruta x Caja", "warning");
					isValidated = false;
					return;
				}

				/* if (this.itemsDetail.length > 0) {
						var count = 0;
						var countVrt = 0;
						this.itemsDetail.forEach(element => {
							if (element.PldFruitxBoxe !== item.PldFruitxBoxe) {
								count += 1;
							}

							if (element.PldFruitxBoxe == item.PldFruitxBoxe) {
								count = 0;
							}

							if (element.VrtID !== item.VrtID) {
								countVrt += 1;
							}

							if (element.VrtID == item.VrtID) {
								countVrt = 0;
							}
						});

						if (count >= 1) {
							this.$fun.alert(
								"Verifique Cantidad de Fruta x Caja",
								"warning",
							);
							return;
						}

						if (countVrt >= 1) {
							this.$fun.alert(
								"Error, no se puede armar Pallet con diferente variedad",
								"warning",
							);
							return;
						}
					} */

				return isValidated;
			},

			recalculateQuantityBox()
			{
				this.itemsBalanceDetail.forEach(element => {
					let quantityBoxAdd = 0;
					this.itemsDetail.forEach(element1 => {
						if(element1.PltIDOLD == element.PltID && element1.PldIDOLD == element.PldID)
						{
							quantityBoxAdd = parseInt(quantityBoxAdd) + parseInt(element1.PldQuantityBoxes);
							console.log('quantityBoxAdd ', quantityBoxAdd);
						}
					});
					
					element.PldQuantityBoxesAvailable = parseInt(element.PldQuantityBoxesAvailable) - quantityBoxAdd;
				});
				this.selectedBalanceDetail = [];
				console.log('itemsBalanceDetail ', this.itemsBalanceDetail);
			},

			newItem() {

				var val = this.selectedBalanceDetail[0];
				if (!this.isValidatedDetail(val)) {
					return;
				}

				if(val.PldQuantityBoxes > val.PldQuantityBoxesAvailable)
				{
					console.log('val.PldQuantityBoxes ', val.PldQuantityBoxes);
					console.log('val.PldQuantityBoxesAvailable ', val.PldQuantityBoxesAvailable);
					this.$fun.alert("Cantidad de cajas supera las disponibles1", "warning");
					return;
				}

				if(this.selectedBalanceDetail[0].PldQuantityBoxes > this.selectedBalance[0].UwoMaterialListNumberBoxes)
				{
					this.$fun.alert("Cantidad de cajas supera las disponibles", "warning");
					return;
				}

				this.pldFruitxBoxe = 0;
				let exist = false;
				this.itemsDetail.forEach((element) => {

					if(val.PldID == element.PldIDOLD && val.TypeCaliber == element.TypeCaliber){
						exist = true;
						this.$fun.alert(
							"Este detalle ya fue agregado",
							"warning"
						);
					}


					if (val.VrtID !== element.VrtID) {
						exist = true;
						this.$fun.alert(
							"No se puede armar Pallet con diferente variedad",
							"warning"
						);
					}

					/* if (
						parseInt(val.PldFruitxBoxe) !==
						parseInt(element.PldFruitxBoxe)
					) {
						exist = true;
						this.$fun.alert(
							"Cantidad de Fruta por Caja no coincide con los item ya agregados, verifique",
							"warning"
						);
					} */
				});

				if (exist == false) {
					var PldLine = this.itemsDetail.length + 1;
					let item = {
						PldLine: PldLine,
						PldID: 0,
						PltID: 0,
						PlbID: this.selectedBalance[0].PlbID,
						RcfID: val.RcfID,
						FagID: val.FagID,
						LlpID: val.LlpID,
						LppID: val.LppID,
						TypeCaliberName: val.TypeCaliberName, //this.objTypeCaliber.DedDescription,
						TypeCaliber: val.TypeCaliber, //this.objTypeCaliber.DedValue,
						VrtID: val.VrtID,
						VrtName: val.VrtName,
						PldQuantityBoxes: val.PldQuantityBoxes,
						PldFruitxBoxe: val.PldFruitxBoxe,
						//PldNumberProcess: 0,

						PldIDOLD: val.PldID,
						PltIDOLD: val.PltID,
						SecStatus: 1,
						UsrCreateID: this.$fun.getUserID(),
						TypeDestiny: this.TypeDestiny
					};
					this.pldFruitxBoxe = val.PldFruitxBoxe;
					this.VrtID = val.VrtID;
					this.itemsDetail.push(item);
				}

				//carlos
				var totalBox = 0;
				this.itemsBalanceDetail.forEach(element => {
					if(element.PldID == val.PldID)
					{
						element.PldQuantityBoxesAvailable = parseInt(element.PldQuantityBoxesAvailable) - parseInt(val.PldQuantityBoxes);
					}
					/* totalBox += parseInt(element.PldQuantityBoxes) */
				})
				this.selectedBalanceDetail = [val];
				/* let itemsBalanceDetailTemp = this.itemsBalanceDetail;
				this.itemsBalanceDetail = [];
				this.itemsBalanceDetail = itemsBalanceDetailTemp;
				console.log('this.itemsBalanceDetail ', this.itemsBalanceDetail); */
				//end
			},

			removeItem() {
				if (this.itemsDetail.length > 0) {
					if (this.selectedDetail[0].PldID > 0) {
						this.selectedDetail[0].SecStatus = 0;
						this.itemsDetailRemoved.push(this.selectedDetail[0]);
					}

					let itemRemove = this.selectedDetail[0];

					this.itemsDetail = this.itemsDetail.filter(
						(x) => x != this.selectedDetail[0]
					);
					this.selectedDetail =
						this.itemsDetail.length > 0 ? [this.itemsDetail[0]] : [];

					console.log("remove", this.itemsDetailRemoved);
					
					//Regresar la cantidad eliminada
					this.itemsBalanceDetail.forEach(element => {
						if(element.PltID = itemRemove.PltIDOLD && element.PldID == itemRemove.PldIDOLD)
						{
							element.PldQuantityBoxesAvailable = parseInt(element.PldQuantityBoxesAvailable) + parseInt(itemRemove.PldQuantityBoxes);
						}
					});
					this.selectedBalanceDetail = [];
					console.log('itemsBalanceDetail ', this.itemsBalanceDetail);
					//end
				}
			},

			//*************************************************************** */

			isValidatedHeader(item) {
				var isValidated = true;

				if (this.itemsDetail.length == 0) {
					this.$fun.alert("No existe Detalle de Empaque", "warning");
					isValidated = false;
					return;
				}

				if (this.viewItemSearch.UwoOrderProductionID == undefined) {
					this.$fun.alert(
						"Seleccione una Orden de Orden de Produccion",
						"warning"
					);
					isValidated = false;
					return;
				}

				if (this.items.OrdID == undefined) {
					this.$fun.alert("Seleccione un numero de Pedido", "warning");
					isValidated = false;
					return;
				}

				if (
					this.sumTotalBoxes !==
					this.viewItemSearch.UwoMaterialListNumberBoxes
				) {
					this.$fun.alert(
						"El Total de Cajas no coincide con el Nro. de Cajas permitidas por el Producto Terminado",
						"warning"
					);
					isValidated = false;
					return;
				}

				return isValidated;
			},

			save() {
				var val = this.selectedBalanceDetail[0];
				/* if (this.selectedSearch.length > 0) { */
				if (!this.isValidatedHeader(val)) {
					return;
				}
				/* } */

				if (this.selectedBalanceDetail.length > 0) {
					(this.items.RcfIDRepresentative = val.RcfID),
						(this.items.TypeBalance = 1),
						(this.items.TypeDestinyPacking = this.ordAreProduction),
						(this.items.UwoMaterialListNumberBoxes =
							this.sumTotalBoxes),
						(this.items.UwoMaterialListID =
							this.viewItemSearch.UwoMaterialListID),
						(this.items.UwoCustomerID =
							this.viewItemSearch.UwoCustomerID),
						(this.items.UwoOrderProductionID =
							this.viewItemSearch.UwoOrderProductionID),
						(this.items.UwoLinePackingID =
							this.viewItemSearch.UwoLinePackingID),
						(this.items.UfdProductionCenter =
							this.viewItemSearch.UfdProductionCenter),
						(this.items.UfdProductionCenterID =
							this.viewItemSearch.UfdProductionCenterID);
					(this.items.TypeCultive = this.viewItemSearch.TypeCultive),
						(this.items.TypeCrop = this.viewItemSearch.TypeCrop),
						(this.items.VrtID = val.VrtID),
						(this.items.FagIDRepresentative =
							this.getFundRepresentative()),
						(this.items.RcfIDRepresentative =
							this.getLotRepresentative()),
						(this.items.RePacking = 2);
					this.items.SecStatus = 1;
					this.items.UsrCreateID = this.$fun.getUserID();

					this.items.PltFruitxBoxe = this.pldFruitxBoxe;

					this.items.itemsDetail = this.itemsDetail;

					console.log("Guardar", this.items);

					this.$fun
						.alert("Esta seguro de Guardar ? ", "question")
						.then((val) => {
							if (val.value) {
								_sPackingService
									.save(this.items, this.$fun.getUserID())
									.then((r) => {
										this.$fun.alert(
											"Registro guardado correctamente",
											"success"
										);
										this.closeModal();
									});
							}
						});
				}
			},

			//*************************************************************** */
		},
	};
</script>
